import React from "react";

const GenerativeAI = () => {
  const title = 'Generative AI Integration';
  const descriptions = [
    "Unlock the power of Generative AI to enhance creativity, automate content generation, and optimize decision-making processes.",
    "Explore AI-driven creativity and efficiency leveraging machine learning. From personalized content to intelligent automation, our services elevate your digital presence, transforming audience engagement.",
    "Partner with us to stay ahead. Harness Generative AI for long-term business impact, shaping a future where innovation and efficiency go hand in hand."
  ];

  const combinedDescription = descriptions.join(' ');

  return (
    <div className="generative-ai-wrapper">
      <div className="container">
        <div className="row row--35 align-items-center">
          <div className="col-lg-5 col-md-12">
            <div className="thumbnail">
              <img src="/assets/images/ai/ai.jpg" alt="Generative AI Images" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="about-inner inner">
              <div className="section-title">
                <h2 className="title">{title}</h2>
                <p className="description">{combinedDescription}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerativeAI;