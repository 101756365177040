import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About Us',
        description = "Innovation takes grit. When we partner with you, that is our #1 commitment. We understand that you've done the due diligence of determining that innovation is needed. Now, it's time for you to turn your blueprints into reality.",
        description2 = "Regardless of the project's size, our goal is to provide you with a team possessing the necessary technical capabilities to ensure that, together, we can achieve your objectives.";

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-us.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;