import React, { Component } from "react";
import { Link } from 'react-router-dom';

class ContactTwo extends Component{
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <span className="subtitle">Let's Say Hi</span>
                                <h2 className="title">Connect With Us</h2>
                                <div className="im_address">
                                    <span>Call us directly:</span>
                                    <a className="link im-hover" href="#contact">(+1) 416-930-9869, (+1) 548-255-2999</a>
                                </div>
                                <div className="im_address mt--5">
                                    <span>Contact Email:</span>
                                    <a className="link im-hover" href="mailto:contact@sharksquad.io">contact@sharksquad.io</a>
                                </div>
                                <div className="im_address mt--5">
                                    <span>LinkedIn:</span>
                                    <a className="link im-hover" href="https://www.linkedin.com/company/sharksquad/about/" target="_blank">LinkedIn</a>
                                </div> 
                                <div className="im_address mt--50">
                                    <div className="ft-text">
                                        <p>Copyright © 2024 <a href="#home">SharkSquad Inc.</a> All rights reserved. Logo credits to <a href="https://www.instagram.com/birintan_designs/" target="_blank">birintan_designs</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 order-1">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/contact/contact-us.jpg" alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;