import React, { Component } from "react";
import { FiCast, FiUsers, FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiMonitor />,
        title: 'Software Engineering',
        description: 'Engineers proficient in the domain of your specific technical requirements and needs.'
    },
    {
        icon: <FiCast />,
        title: 'Strategy',
        description: 'Multi-faceted planning resources and capability to incorporate an effective strategizing process.'
    },
    {
        icon: <FiUsers />,
        title: 'Market Research',
        description: 'Market analysis focused on customer-centric insights for making the right product decisions.'
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'Our Services',
        description = 'Our team offers services committed to your long-term business impact.',
        subtitle = 'What We Can Do for You';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        {/* <a href="/service-details"> */}
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        {/* </a> */}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
