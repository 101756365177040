import React, {Component, Fragment} from "react";
import ScrollToTop from 'react-scroll-up';
import {
    FiChevronUp
} from "react-icons/fi";
import 'react-accessible-accordion/dist/fancy-example.css';
import Header from "../component/header/Header";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import About from "../component/HomeLayout/About";
import Portfolio from "../component/HomeLayout/Portfolio";
import GenerativeAI from "../component/HomeLayout/GenerativeAI";
import Helmet from "../component/common/Helmet";
import ContactTwo from "../elements/contact/ContactTwo";

class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        return(
            <div className="active-dark"> 
                <Helmet pageTitle="SharkSquad" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                <div className="slider-wrapper" id="home">
                    <SliderOne />
                </div>

                <div className="about-area ptb--120 bg_color--1" id="ai">
                    <GenerativeAI />
                </div>

                <div className="service-area ptb--120 bg_color--5" id="services">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>

                <div className="portfolio-area ptb--120 bg_color--1" id="portfolio">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>

                <div className="portfolio-area ptb--120 bg_color--5" id="aboutUs">
                    <div className="container">
                        <About />
                   </div>
                </div>

                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>

                <footer className="footer-area footer-style-01 bg_color--6">
                    <div className="im-call-to-action-area ptb--70 im-separator">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                                    <div className="inner">
                                        <h2 className="text-white mb--0">Enough Talk, Let's Build Something Together</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container" id="contactUs">
                        <div className="row">
                            <div className="rn-contact-page ptb--120 bg_color--5">
                                <ContactTwo />
                            </div>
                        </div>
                    </div>
                </footer>
                
            </div>
        )
    }
}
export default LandingPage;