import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import { FiCheck } from "react-icons/fi";

const projectList = [
    {
        hookQuote: "Finding depth in relationships",
        hookCaption: "Creating an internet platform to help with the growth of personal relationships",
        name: "Piky.me",
        type: "Web Platform",
        technicalCollaboration: "Fullstack (Infra + Backend + Frontend)",
        nontechnicalCollaboration: "User Research + Market Strategy",
        description: "How can we find depth in relationships? How can we learn more about each other and make it a regular routine? How can we discover more meaningful relationships? Piky.me is a platform that provides users with a broad range of one-to-one and one-to-many interactive quiz modes. Users use these quiz modes to learn more about each other.",
        imgList: ["/assets/images/portfolio/pikyme_main_screen_glass.png"],
        collaborationPoints: [
            "Used the agile development process to discover user demand and expanded the platform to include multiple modes",
            "Participated heavily in vertical project strategy and market research using user-centric learning models",
            "Created an efficient platform expansion framework by introducing plug-and-play architecture for quick addition of new applications and quiz modes in the library",
        ],
        checkedPoints: [
            "Persistent rooms with daily new games",
            "Live sessions between users",
            "Create & share scheme for async modes"
        ]
    },
    {
        hookQuote: "The everything platform for university",
        hookCaption: "A student's most resourceful tool to manage personal and academic life",
        name: "Unify",
        type: "Mobile Platform",
        technicalCollaboration: "Fullstack (Infra + Backend + Frontend)",
        nontechnicalCollaboration: "Market Strategy",
        description: "How can we amplify student integration on campus through technology? The solution is the Unify platform, a comprehensive platform with multiple key features focused on a student's life on campus. All users have prime access to events, activities, their academic information, and in-app interactive features to amplify their social life at a pace they're comfortable with.",
        imgList: [
            "/assets/images/portfolio/unify_events_screen_glass.png",
            "/assets/images/portfolio/unify_calendar_screen_glass.png",
            "/assets/images/portfolio/unify_chat_screen_glass.png",
        ],
        collaborationPoints: [
            "Microservices-based architecture with dynamic scaling enabled based on volume and traffic parameters",
            "Modular implementation of the data-layer to allow for complete integration of university-based academic data and crowd-sourced featuresets",
            "Multi-user chat and interactivity features with automatic grouping functionality",
            "Parametric user-matching algorithm with a high success rate at matching users effectively based on various qualitative characteristics"
        ],
        checkedPoints: [
            "Calendar & Events",
            "Class groups",
            "User matching (Interest-based)",
            "In-app chat",
            "Restrictive Authentication"
        ]
    },
    {
        hookQuote: "The Latest on COVID-19, Simplified.",
        hookCaption: "A platform for the most updated information on the global pandemic",
        name: "TRCO",
        type: "Web Platform",
        technicalCollaboration: "Fullstack (Infra + Backend + Frontend)",
        nontechnicalCollaboration: "User Research + Market Strategy",
        description: "How do we create a one-stop shop about all things COVID-19 with the caliber to stay up to date with the latest information for all places around the world? How do we make it easily digestible and not overwhelming? How do we show our users the signal amidst all the noise?",
        imgList: [
            "/assets/images/portfolio/trco_case_count_screen_glass.png",
            "/assets/images/portfolio/trco_news_screen_glass.png",
        ],
        collaborationPoints: [
            "Comprehensive platform (fully-loaded quantitative + non-quantitative features)",
            "Mobile-first, minimal React-based UI produced through iterative LEAN user research centered on ease of access and consumption",
            "Perpetual ahead-of-time data pipelining and aggregation for fresh, curated data on demand",
            "News story observation framework capable of compiling and presenting the latest geographically organized articles and similar non-quantitative data ad hoc",
            "All features at scale and available for 200+ countries around the world"
        ],
        checkedPoints: [
            "Up-to-date case counts",
            "Live news updates (location-based)",
            "Multi-source integration"
        ]
    },
    {
        hookQuote: "The School Management ERM",
        hookCaption: "Centralized Management System for School Operations and Activities",
        name: "Acadsea",
        type: "Web + Mobile Platform",
        technicalCollaboration: "Fullstack (Infra + Backend + Frontend)",
        nontechnicalCollaboration: "User Research + Market Strategy",
        description: "How can we create a centralized management system for school operations and activities? Is there a better way of managing all the school activities and operations such as attendance, scheduling tests, and so on than a traditional offline notebook?",
        imgList: [
            "/assets/images/portfolio/acadsea_exams_screen_glass.png",
            "/assets/images/portfolio/acadsea_fee_details_screen_glass.png",
        ],
        collaborationPoints: [
            "Exclusive dashboard for the Management & Staff to oversee all the school activities and operations",
            "Hybrid mobile applications for Student / Parent and Teacher",
        ],
        checkedPoints: [
            "Students attendance",
            "Scheduling quizzes and exams",
            "Setting up online payments and fees",
            "Chatting module for interacting with teachers and students",
            "Uploading documents and displaying notices"
        ]
    },
    {
        hookQuote: "The Job Search Platform",
        hookCaption: "Facilitating the connection between local startups and students",
        name: "StartConnect",
        type: "Web Platform",
        technicalCollaboration: "Fullstack (Infra + Backend + Frontend)",
        nontechnicalCollaboration: "User Research + Market Strategy",
        description: "The majority of talent search platforms are geared towards experienced employees. Students with little to no experience find it hard to find internships, jobs, or volunteering work. How can we facilitate the connection between companies and students?",
        imgList: [
            "/assets/images/portfolio/start_connect_screen_glass.png",
        ],
        collaborationPoints: [
            "Different workflows for employers and students UI(s)",
            "Location-based search for targeting local companies",
        ],
        checkedPoints: [
            "Separate account creation process for both employers and students",
            "Exclusive dashboard for employers with various functionalities such as posting jobs, searching for candidates' profiles, filtering resumes, and so on",
            "Allowing students to target local companies or start-ups and submitting applications with ease",
        ]
    },
];

const projectCodes = ["piky", "unify", "trco", "acadsea", "startconnect"]

class PortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal () {
        this.setState({isOpen: true})
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render(){
        const projectCode = this.props.match.params.project_name;
        let project = {};
        if (projectCode === "piky") {
            project = projectList[0]
        } else if (projectCode === "unify") {
            project = projectList[1]
        } else if (projectCode === "trco") {
            project = projectList[2]
        } else if (projectCode === "acadsea") {
            project = projectList[3]
        } else if (projectCode === "startconnect") {
            project = projectList[4]
        }
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{project.hookQuote}</h2>
                                    <p>{project.hookCaption}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">{project.name}</h2>
                                                    <p className="description mt--30">{project.description}</p>
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Project Type</span>
                                                        <h4>{project.type}</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Technical Collaboration</span>
                                                        <h4>{project.technicalCollaboration}</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Non-Technical Collaboration</span>
                                                        <h4>{project.nontechnicalCollaboration}</h4>
                                                    </div>
                                                </div>
                                                <div className="portfolio-details-btn mt--30">
                                                    {!projectCodes.includes(projectCode) ? (<a className="btn-default btn-border" href="/portfolio">Launch The Site</a>) : (<div></div>)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Our Collaboration</h4> 
                                                        <ul>
                                                            {project.collaborationPoints.map((value, index) => (
                                                                <li key={index}>
                                                                    {value}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        <ul className="list-style--1">
                                                            {project.checkedPoints.map((value, index) => (
                                                                <li key={index}><FiCheck /> {value}</li>
                                                            ))}
                                                        </ul>     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        {!projectCodes.includes(projectCode) ? (
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        ) : (<div></div>)}

                                        <div className="container">
                                            <div className="row">
                                            {project.imgList.map((imgSrc, index) => (
                                                <div className="col thumb mb--30" key={index}>
                                                    <img src={imgSrc} alt={project.name + " image"}/>
                                                </div>
                                            ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </React.Fragment>
        )
    }
}
export default PortfolioDetails;
