import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/trco.png" alt="TRCO" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/piky.png" alt="Piky.me" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/unify_events_screen.png" alt="Unify" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/acadsea.png" alt="Acadsea" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/startconnect.png" alt="StartConnect" />;

const PortfolioList = [
    {
        image: Portfolio_image,
        title: 'TRCO',
        description: 'All you need to know about the global pandemic in one place',
        link: '/portfolio/trco'
    },
    {
        image: Portfolio_image3,
        title: 'Unify',
        description: 'The all-encompassing platform for university students',
        link: '/portfolio/unify'
    },
    {
        image: Portfolio_image2,
        title: 'Piky.me',
        description: 'An online, questions-based social tool to foster relationships',
        link: '/portfolio/piky'
    },
    {
        image: Portfolio_image4,
        title: 'Acadsea',
        description: 'Centralized Management System for School Operations and Activities',
        link: '/portfolio/acadsea'
    },
    {
        image: Portfolio_image5,
        title: 'StartConnect',
        description: 'Facilitating the connection between local startups and students',
        link: '/portfolio/startconnect'
    },
]

class Portfolio extends Component{
    render(){
        let title = 'Some of Our Recent Work',
        description = 'We are domain-agnostic and vision-reliant to meet the needs of businesses in today’s digital age.'
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Our Workmanship</span>
                                    <h2 className="title">{title}</h2>
                                    <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={index}>
                                    <a href={value.link}>
                                        <div className="im_portfolio">
                                            <div className="thumbnail_inner">
                                                <div className="thumbnail">
                                                    <Link to="/portfolio-details">
                                                        {value.image}
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <div className="portfolio_heading">
                                                        <h4 className="title">{value.title}</h4>
                                                    </div>
                                                    <div className="portfolio_hover">
                                                        <p>{value.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;